import { Box } from '@mui/material';
import { useLocale, useComponentVisibility } from '@koopajs/react';
import { useCallback } from 'react';
import { IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { DocumentAnnotation } from 'components/DocumentAnnotation';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Stack } from '@mui/material';
import { useScreenSize } from 'utils/useScreenSize';

export const ViewDocumentPdf: React.FC = () => {
  const { t } = useLocale();

  const keyPrefix = 'Dialogs.DocumentPdfDialog';

  const dialog = useComponentVisibility('documentPdfDialogView');
  const { documentId } = dialog.getContext() as {
    documentId?: string;
  };

  const { isScreenAboveMd } = useScreenSize();

  const handleClose = useCallback(async () => {
    dialog.setHidden();
  }, []);

  if (!documentId) {
    return null;
  }

  return (
    <Dialog
      PaperProps={{ sx: { height: '100%' } }}
      open={dialog.isVisible}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      fullScreen={!isScreenAboveMd}
      data-cy="document-pdf-dialog"
    >
      <DialogTitle data-cy="document-pdf-dialog_title">
        {t(keyPrefix + '.titleView')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          data-cy="document-pdf-dialog_close-button"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey'
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <DocumentAnnotation
          documentId={documentId}
          sxContainer={{ height: '100%' }}
          sx={{
            height: '100%'
          }}
          testId="document-annotation-iframe"
        />
      </DialogContent>

      <DialogActions sx={{ mx: 2 }}>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Box sx={{ flexBasis: 1 }} />

          <Stack direction="row" spacing={2} alignItems="center" sx={{ textAlign: 'right' }}>
            <Button onClick={handleClose} size="small">
              {t('common:close')}
            </Button>
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
